<template>
  <div class="addenterprise">
    <div class="store_table">
      <div class="store_table_item">
        <span>智参中心资料 </span>
      </div>
    </div>
    <div class="essential_information">
      <div class="form_essential">
        <el-form ref="form" :model="form" :rules="rules" label-width="120px">
          <div>
            <el-form-item label="封面图:" prop="coverImage">
              <img-big-upload
                :imageShow.sync="form.coverImage"
                :addTitleShow="false"
                @removeImg="removeImgDians"
                @uploadOneImgShow="uploadOneImgShowDians"
              />
            </el-form-item>
            <span
              style="font-size: 14px; color: #6ba1ff; position: relative; left: 100px; top: -10px"
              >(建议整体尺寸为200*200px，图片格式应为jpg、jpeg、png，图片应小于6MB)</span
            >

            <el-form-item label="中心名称:" prop="zcName">
              <el-input v-model="form.zcName" placeholder="请输入渠道名称" />
            </el-form-item>


            <el-form-item label="所在地区:" prop="city">
              <div class="select_inner">
                <!-- <el-select
                  v-model="form.country"
                  placeholder="请选择国家"
                  style="margin-right: 10px"
                >
                  <el-option value="中国"> </el-option>
                </el-select> -->
                <el-select v-model="form.province" placeholder="请选择省份" @change="citychange">
                  <el-option
                    v-for="item in operatingList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.city" placeholder="请选择地区" @change="districtchange">
                  <el-option
                    v-for="item in operatingLists"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
                <div style="display: inline-block; width: 10px"></div>
                <el-select v-model="form.district" placeholder="请选择地区">
                  <el-option
                    v-for="item in districtList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item label="中心简介:" prop="zcIntro">
              <el-input v-model="form.zcIntro" type="textarea" placeholder="请输入渠道简介" />
            </el-form-item>
          </div>
          <h4 class="h4_share">小程序分享链接信息</h4>
          <el-form-item label="分享链接标题:" prop="">
            <el-input v-model="form.shareTitle" placeholder="请输入分享链接标题" />
          </el-form-item>
          <el-form-item label="分享链接图片:" prop="">
            <div class="pictures_box_left">
              <img-big-upload
                :imageShow.sync="form.shareImage"
                :addTitleShow="false"
                @removeImg="removeImgDian"
                @uploadOneImgShow="uploadOneImgShowDian"
              />
            </div>
            <div class="img_tips">
              <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-top: 30px" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="clcans">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addwisdomGinsengPage, updateWisdomGinseng, getWisdomGinsengDetail } from '@/api/channel'
import { getByFatherIdt, getFieldList, getProvince } from '@/api/demand'

export default {
  name: 'addginseng',
  computed: {
    id() {
      return this.$route.query.id
    },
    pageNum() {
      return this.$route.query.pageNum
    }
  },
  data() {
    return {
      form: {
        country: '中国',
        city:null,
        district:null, //区
        coverImage: '',
        createId: null,
        createTime: null,
        id: null,
        province: null,
        shareImage: null,
        shareTitle: "企业数智化转型的“专属参谋”",
        updateId:null,
        updateTime: null,
        zcIntro: null,
        zcName: null
      }, //表单收集
      param: {},
      operatingLists: [],
      operatingList: [],
      districtList: [],

      rules: {
        zcName: [{ required: true, message: '请输入中心名称', trigger: 'blur' }],
        partnerWebsite: [{ required: true, message: '请输入渠道官网', trigger: 'blur' }],
        partnerContactPerson: [{ required: true, message: '请输入渠道对接人', trigger: 'blur' }],
        partnerType: [{ required: true, message: '请勾选渠道类型', trigger: 'blur' }],
        partnerIntro: [{ required: true, message: '请输入渠道简介', trigger: 'blur' }],

        city: [{ required: true, message: '请选择地区', trigger: 'change' }],
        concatPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },

          {
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        coverImage: [{ required: true, message: '请上传封面图', trigger: 'change' }]
      }
    }
  },
  created() {
    this.incity()
    this.selectPartnerById()
  },
  methods: {
    async selectPartnerById() {
      const res = await getWisdomGinsengDetail({ id: this.id })
      if (res.data.resultCode == 200) {
        this.form = res.data.data
      }
    },

    // 图片上传路径长地址  logo
    uploadOneImgShow(value) {
      this.form.coverImage = value
    },

    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.province == o.name) {
          this.form.city = ''
          // this.operatingLists = item.cityResults
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            // console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.form.city == o.name) {
          this.form.district = ''
          // this.districtList = item.cityResults
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
            console.log(this.districtList, 'this.districtList ')
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
      // console.log("item",item);
      // console.log("区",this.operatingLists.cityResults);
    },

    storeClick(id) {
      this.typeIndex = id
    },
    //获取长地址图片
    uploadOneImgShowDian(val) {
      this.form.shareImage = val
    },
    /**
     * 删除图
     */
    removeImgDian(val) {
      this.form.shareImage = ''
    },
    //获取长地址图片
    uploadOneImgShowDians(val) {
      this.form.coverImage = val
    },
    /**
     * 删除图
     */
    removeImgDians(val) {
      this.form.coverImage = ''
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.form.id) {
            addwisdomGinsengPage(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.$router.go(-1)
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            updateWisdomGinseng(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.$router.go(-1)
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },
    clcans() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/channel/ginseng',
        query: {
          changeNum: true
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-checkbox-group {
  font-size: 0;
  width: 95%;
}
::v-deep .label_code .el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 16px;
  color: #333333;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
::v-deep .label_code .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  color: #333333;
}
::v-deep .el-radio {
  padding: 5px 0px;
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  margin-right: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::v-deep .essential_information .companyName .el-input {
  width: 596px;
}
::v-deep .essential_information .el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 596px;
  height: 100px;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

::v-deep .essential_information .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .select_inner .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 192px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 590px;
}
::v-deep .date_picker .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 30px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cascsder_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 300px;
}
.addenterprise {
  width: 100%;
  background: #ffffff;
  padding: 10px 35px;
  .label_code {
    padding: 30px 0px;
  }
  .child_label {
    background: #f5f6f7;
    padding: 10px 15px;
    display: flex;
    align-items: baseline;
    width: 80%;
  }
  .store_table {
    height: 70px;
    box-sizing: border-box;
    margin: 0 72px 20px 0;
    padding-left: 10px;
    display: flex;

    .store_table_item {
      color: #4e93fb;
      margin-right: 50px;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 20px;
    }

    .active {
      color: #4e93fb;
      font-size: 20px;
      border-bottom: 2px solid #4e93fb;
      border-radius: 1px;
    }
  }

  .img_tips {
    position: relative;
    top: -10px;
    color: #6ba1ff;
  }

  .essential_information {
    h4 {
      color: #146aff;
    }
  }

  .label_management {
    padding: 30px 10px;

    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: right;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
  .dialog-footer {
    border-top: 1px solid #f5f5f5;
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
